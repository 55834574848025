import type { ElementIndividualChange } from '../../../types';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';

interface Props {
    change: ElementIndividualChange;
}

export const DiffSection = ({ change }: Props) => {
    if ('diff' in change) {
        return (
            <div className="diff change-body">
                <ReactDiffViewer
                    oldValue={change.diff.before ?? ''}
                    newValue={change.diff.after ?? ''}
                    compareMethod={DiffMethod.WORDS}
                    hideLineNumbers={false}
                />
            </div>
        );
    }
    return null;
};

export default DiffSection;
