import { type ReactNode, useCallback, useState } from 'react';
import { getSnapshotsWithEnvironmentInfo } from '../../../sources/flow';
import type { NotifyError, ReleasedSnapshot } from '../../../types';
import type { Environment } from '../../../types/environment';

export interface FlowEditorSharedData {
    releases: ReleasedSnapshot[];
    environments: Environment[];
    loadReleases: () => void;
    isLoadingReleases: boolean;
}

interface Props {
    flowId: string;
    notifyError: NotifyError;
    children: (props: FlowEditorSharedData) => ReactNode;
}

const FlowEditorSharedWrapper = ({ flowId, notifyError, children }: Props) => {
    const [releases, setReleases] = useState<ReleasedSnapshot[]>([]);
    const [environments, setEnvironments] = useState<Environment[]>([]);
    const [isLoadingReleases, setIsLoadingReleases] = useState(false);

    const loadReleases = useCallback(async () => {
        setIsLoadingReleases(true);
        try {
            const { releases, environments } = await getSnapshotsWithEnvironmentInfo(flowId);
            setReleases(releases);
            setEnvironments(environments);
            setIsLoadingReleases(false);
        } catch (error) {
            notifyError(error);
            setIsLoadingReleases(false);
        }
    }, [flowId, notifyError]);

    return (
        <>
            {children({
                releases,
                environments,
                loadReleases,
                isLoadingReleases,
            })}
        </>
    );
};

export default FlowEditorSharedWrapper;
