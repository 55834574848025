import { FLOW_EDITING_TOKEN } from '../constants';
import type {
    ElementAPI,
    FlowRequestAPI,
    FlowResponseAPI,
    NavigationElementResponseAPI,
    PackageConflictResponse,
    ReleasesWithEnvironments,
    SnapDiffAPI,
} from '../types';
import type {
    Culture,
    CultureApi,
    TranslateFlowExportRequest,
    TranslateFlowImportRequest,
} from '../types/translation';
import { fetchAndParse, CheckStatus, handleConflict, parse } from '../utils/ajax';
import { getTenantId } from '../utils/tenant';

interface SharedPackageRequestAPI {
    token: string;
}

interface SharedPackageResponseAPI extends SharedPackageRequestAPI {}

export const getActivatedFlow = async (flowId: string) => {
    const response = await fetchAndParse<FlowResponseAPI>({
        url: `/api/draw/1/flow/active/${flowId}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

    return response;
};

export const getActivated = async (tenantId: string) => {
    const response = await fetchAndParse<FlowResponseAPI[]>({
        url: '/api/draw/1/flow/active',
        headers: {
            ManyWhoTenant: tenantId,
        },
    });

    return response;
};

export const getActivatedForEnvironment = async (tenantId: string, environmentId: string) => {
    const response = await fetchAndParse<FlowResponseAPI[]>({
        url: `/api/draw/1/flow/active/environment/${environmentId}`,
        headers: {
            ManyWhoTenant: tenantId,
        },
    });

    return response;
};

export const getFlows = async (tenantId: string) => {
    const response = await fetchAndParse<FlowResponseAPI[]>({
        url: '/api/draw/1/flow/',
        headers: {
            ManyWhoTenant: tenantId,
        },
    });

    return response;
};

export const getLatest = async (id: string) => {
    const response = await fetchAndParse<FlowResponseAPI>({
        url: `/api/draw/1/flow/${id}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

    return response;
};

export const getSnapshots = async (id: string) => {
    const response = await fetchAndParse<FlowResponseAPI[]>({
        url: `/api/draw/1/flow/snap/${id}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

    return response;
};

export const getSnapshotsWithEnvironmentInfo = async (id: string) => {
    const response = await fetchAndParse<ReleasesWithEnvironments>({
        url: `/api/draw/1/flow/snap/environments/${id}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

    return response;
};

export const addElement = async (
    elementId: string,
    flowId: string,
    elementType: string,
    tenantId: string,
) => {
    const response = await fetchAndParse<Response>({
        url: `/api/draw/1/element/flow/${flowId}/${elementType}/${elementId}`,
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: tenantId,
        },
    });

    return response;
};

export const removeElement = async (
    elementId: string,
    flowId: string,
    elementType: string,
    tenantId: string,
) => {
    const response = await fetchAndParse({
        url: `/api/draw/1/element/flow/${flowId}/${elementType}/${elementId}`,
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: tenantId,
        },
    });

    return response;
};

export const setLatest = async (flowData: FlowRequestAPI) => {
    const response = await fetchAndParse<FlowResponseAPI>({
        url: '/api/draw/1/flow',
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: JSON.stringify(flowData),
    });

    return response;
};

export const activateFlowSnapshot = async (flowId: string, versionId: string) => {
    const response = await fetchAndParse<FlowResponseAPI>({
        url: `/api/draw/1/flow/activation/${flowId}/${versionId}/true/true`,
        method: 'post',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

    return response;
};

export const deactivateAllFlowSnapshots = async (flowId: string) => {
    await fetch(`/api/draw/1/flow/deactivate/${flowId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
    });
};

export const exportFlow = async (
    id: string,
    tenantId: string,
    includePasswords: boolean,
    environmentId: string,
) => {
    let url = `/api/package/1/flow/${id}`;

    if (environmentId) {
        url += `/environment/${environmentId}`;
    }

    url += `?nullPasswords=${includePasswords ? 'false' : 'true'}`;

    return fetch(url, {
        headers: {
            ManyWhoTenant: tenantId,
        },
    })
        .then(CheckStatus)
        .then((response) => response.text());
};

export const shareFlow = async (
    id: string,
    tenantId: string,
    includePasswords: boolean,
    environmentId: string,
) => {
    let url = `/api/package/1/flow/${id}/share`;

    if (environmentId) {
        url += `/environment/${environmentId}`;
    }

    url += `?nullPasswords=${includePasswords ? 'false' : 'true'}`;

    const response = await fetchAndParse<SharedPackageResponseAPI>({
        url,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ManyWhoTenant: tenantId,
        },
    });

    return response;
};

export const importFlow = async (json: string, tenantId: string, force: boolean) => {
    const response = await fetch(`/api/package/1/flow${force ? '' : '?overwriteExisting=false'}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: tenantId,
        },
        body: json,
    });

    return (await parse(await CheckStatus(await handleConflict(response)))) as
        | FlowResponseAPI
        | PackageConflictResponse;
};

export const importSharedFlow = async (token: string, tenantId: string, force: boolean) => {
    const response = await fetch(
        `/api/package/1/shared/flow${force ? '' : '?overwriteExisting=false'}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ManyWhoTenant: tenantId,
            },
            body: JSON.stringify({
                token,
            }),
        },
    );

    return (await parse(await CheckStatus(await handleConflict(response)))) as
        | FlowResponseAPI
        | PackageConflictResponse;
};

export const getFlowSharedElements = async (flowId: string, elementType: string) => {
    const response = await fetchAndParse<ElementAPI[]>({
        url: `/api/draw/1/element/flow/${flowId}/${elementType}`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
    });

    return response;
};

export const deleteFlow = async (id: string, tenantId: string) => {
    const response = await fetchAndParse<Response>({
        url: `/api/draw/1/flow/${id}`,
        method: 'DELETE',
        headers: {
            ManyWhoTenant: tenantId,
        },
    });

    return response;
};

export const getNavigationElements = async ({
    tenantId,
    flowId,
    navigationElementId = null,
}: {
    tenantId: string;
    flowId: string;
    navigationElementId: string | null;
}) => {
    const response = await fetchAndParse<NavigationElementResponseAPI>({
        url: `/api/draw/1/flow/${flowId}/${FLOW_EDITING_TOKEN}/element/navigation/${
            navigationElementId ?? ''
        }`,
        method: 'GET',
        headers: {
            ManyWhoTenant: tenantId,
        },
    });

    return response;
};

export const createOrUpdateNavigationElements = async (
    { tenantId, flowId }: { tenantId: string; flowId: string },
    navigationElement: string,
) => {
    const response = await fetchAndParse<NavigationElementResponseAPI[]>({
        url: `/api/draw/1/flow/${flowId}/${FLOW_EDITING_TOKEN}/element/navigation`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: tenantId,
        },
        body: navigationElement,
    });

    return response;
};

export const deleteNavigationElement = async ({
    tenantId,
    flowId,
    navigationElementId,
}: {
    tenantId: string;
    flowId: string;
    navigationElementId: string;
}) => {
    const response = await fetchAndParse<Response>({
        url: `/api/draw/1/flow/${flowId}/${FLOW_EDITING_TOKEN}/element/navigation/${navigationElementId}`,
        method: 'DELETE',
        headers: {
            ManyWhoTenant: tenantId,
        },
    });

    return response;
};

export const exportFlowsForTranslation = async ({
    tenantId,
    translateFlowExportRequest,
}: {
    tenantId: string;
    translateFlowExportRequest: TranslateFlowExportRequest;
}) => {
    const response = await fetchAndParse<string>({
        url: '/api/translate/1/flows/export/',
        method: 'POST',
        headers: {
            ManyWhoTenant: tenantId,
        },
        body: JSON.stringify(translateFlowExportRequest),
    });

    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(new Blob([response], { type: 'text/xml' }));
    elem.download = 'flow-pre-translation.xlf';
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);

    return response;
};

export const importFlowTranslation = async ({
    tenantId,
    translateFlowImportRequest,
}: {
    tenantId: string;
    translateFlowImportRequest: TranslateFlowImportRequest;
}) => {
    const response = await fetchAndParse<Response>({
        url: '/api/translate/1/flows/import',
        method: 'POST',
        headers: {
            ManyWhoTenant: tenantId,
        },
        body: JSON.stringify(translateFlowImportRequest),
    });

    return response;
};

export const getTranslationLanguagesForFlow = async ({
    tenantId,
    flowId,
}: {
    tenantId: string;
    flowId: string;
}) => {
    const response = await fetchAndParse<CultureApi[]>({
        url: `/api/translate/1/${flowId}/translations`,
        method: 'GET',
        headers: {
            ManyWhoTenant: tenantId,
        },
    });

    return response;
};

export const getDefaultCulture = async ({ tenantId }: { tenantId: string }) => {
    const response = await fetchAndParse<CultureApi>({
        url: '/api/translate/1/default-culture',
        method: 'GET',
        headers: {
            ManyWhoTenant: tenantId,
        },
    });

    return response;
};

export const getAllCultures = async ({ tenantId }: { tenantId: string }) => {
    const response = await fetchAndParse<Culture[]>({
        url: '/api/translate/1/cultures',
        method: 'GET',
        headers: {
            ManyWhoTenant: tenantId,
        },
    });

    return response;
};

export const getSnapshotDiffs = async (
    flowId: string,
    beforeVersionId: string,
    afterVersionId: string,
) => {
    const response = await fetchAndParse<SnapDiffAPI>({
        url: `/api/draw/1/flow/snap/${flowId}/diff/${beforeVersionId}/${afterVersionId}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

    return response;
};

export const revertFlow = async (
    flowId: string,
    versionId: string,
    force: boolean,
    comment: string,
) => {
    const response = await fetch(
        `/api/draw/2/flow/revert/${flowId}/${versionId}?overwriteExisting=${force}&automaticSnapshotComment=${comment}`,
        {
            method: 'POST',
            headers: {
                ManyWhoTenant: getTenantId(),
            },
        },
    );

    return (await parse(await CheckStatus(await handleConflict(response)))) as
        | FlowResponseAPI
        | PackageConflictResponse;
};
