import { BadgeColor } from '@boomi/exosphere';
import type {
    ElementIndividualChange,
    FlowResponseAPI,
    NotifyError,
    PackageConflictResponse,
    ReleasedSnapshot,
    SnapDiffAPI,
} from '../../../types';
import { getSnapshotDiffs, revertFlow } from '../../../sources/flow';
import { stringReplace } from '../../../utils';
import translations from '../../../translations';

export const getVersionId = (snapshot: ReleasedSnapshot | FlowResponseAPI) => {
    if ('versionId' in snapshot) {
        return (snapshot as ReleasedSnapshot).versionId;
    }
    return (snapshot as FlowResponseAPI).id.versionId;
};

export const getVersionName = (snapshot: ReleasedSnapshot | FlowResponseAPI) =>
    new Date(snapshot.dateCreated).toLocaleString(undefined, {
        dateStyle: 'medium',
        timeStyle: 'short',
    });

export const getCreator = (snapshot: ReleasedSnapshot | FlowResponseAPI) =>
    'whoCreated' in snapshot ? snapshot.whoCreated : snapshot.userCreated;

export const getSnapshotCreators = (snapshots: (ReleasedSnapshot | FlowResponseAPI)[]) =>
    snapshots.reduce((acc: string[], snapshot) => {
        const creator = getCreator(snapshot);
        if (creator && !acc.includes(creator.id)) {
            acc.push(creator.id);
        }
        return acc;
    }, []);

export const environmentTypeToColour = (environmentType?: string) =>
    environmentType === 'Production'
        ? BadgeColor.GREEN
        : environmentType === 'Test'
          ? BadgeColor.YELLOW
          : BadgeColor.BLUE;

interface ConflictError {
    conflictingDependents: PackageConflictResponse;
    wasConflict: boolean | undefined;
}

export const revertVersion = async (
    snapshot: ReleasedSnapshot | FlowResponseAPI,
    flowId: string,
    refreshFlow: () => void,
    setRestoringVersion: React.Dispatch<
        React.SetStateAction<ReleasedSnapshot | FlowResponseAPI | undefined>
    >,
    setConflictingDependents: React.Dispatch<
        React.SetStateAction<PackageConflictResponse | undefined>
    >,
    force: boolean,
) => {
    const versionId = getVersionId(snapshot);
    try {
        await revertFlow(
            flowId,
            versionId,
            force,
            stringReplace(
                translations.FLOW_HISTORY_automatic_restore_build_comment,
                getVersionName(snapshot),
            ),
        );
        refreshFlow();
        setRestoringVersion(undefined);
        setConflictingDependents(undefined);
    } catch (error) {
        if ((error as ConflictError).wasConflict) {
            setRestoringVersion(snapshot);
            setConflictingDependents((error as ConflictError).conflictingDependents);
        }
    }
};

export const calculateHistory = async (
    beforeVersion: ReleasedSnapshot,
    afterVersion: ReleasedSnapshot,
    setHistoryBeforeVersion: React.Dispatch<React.SetStateAction<ReleasedSnapshot | null>>,
    setHistoryAfterVersion: React.Dispatch<React.SetStateAction<ReleasedSnapshot | null>>,
    flowId: string,
    setSnapshotDiffs: React.Dispatch<React.SetStateAction<SnapDiffAPI>>,
    inspectHistory: (givenSnapshotDiffs: SnapDiffAPI | null) => void,
    notifyError: NotifyError,
    setIsLoadingHistoryData: (isOpen: boolean) => void,
    setIsViewChangesModalOpen: (isOpen: boolean) => void,
) => {
    setIsLoadingHistoryData(true);
    setIsViewChangesModalOpen(true);
    try {
        setHistoryBeforeVersion(beforeVersion);
        setHistoryAfterVersion(afterVersion);
        const diff = await getSnapshotDiffs(
            flowId,
            getVersionId(beforeVersion),
            getVersionId(afterVersion),
        );
        setSnapshotDiffs(diff);
        inspectHistory(diff);
        setIsLoadingHistoryData(false);
    } catch (error) {
        notifyError(error);
        setIsLoadingHistoryData(false);
    }
};

export const inspectHistory = (
    snapshotDiffs: SnapDiffAPI,
    setHistoryData: React.Dispatch<React.SetStateAction<ElementIndividualChange[] | null>>,
    givenSnapshotDiffs: SnapDiffAPI | null = null,
) => {
    const readingSnapshotDiffs = givenSnapshotDiffs ?? snapshotDiffs;
    if (
        (readingSnapshotDiffs.removed?.length ?? 0) > 0 ||
        (readingSnapshotDiffs.added?.length ?? 0) > 0 ||
        (readingSnapshotDiffs.diffs?.length ?? 0) > 0
    ) {
        return setHistoryData(
            (
                [
                    ...(readingSnapshotDiffs.removed?.map((r) => ({
                        ...r,
                        operationType: 'removed',
                    })) ?? []),
                    ...(readingSnapshotDiffs.added?.map((r) => ({
                        ...r,
                        operationType: 'added',
                    })) ?? []),
                    ...(readingSnapshotDiffs.diffs?.flatMap((element) =>
                        element.diffs?.map((diff) => ({
                            ...element,
                            operationType: 'changed',
                            diffs: undefined,
                            diff,
                        })),
                    ) ?? []),
                ] as ElementIndividualChange[]
            ).sort((a, b) => getChangeName(a).localeCompare(getChangeName(b))),
        );
    }
    return setHistoryData([]);
};

export const elementTypeToGroupName = (elementType: string) =>
    `${elementType.substring(0, 1).toUpperCase() + elementType.substring(1).toLowerCase()}s`;

export const getChangePath = (change: ElementIndividualChange) => [
    elementTypeToGroupName(change.elementType),
    change.developerName,
    ...(change?.diff?.path ?? []),
];

export const getChangeName = (change: ElementIndividualChange) => getChangePath(change).join(' / ');
