// Separate version for page conditions as it is slightly modified.

import type { RuleCriteria } from '../../../../../../types';

// Original is kept as-is since it is already used in business rules.
export const RULE_CRITERIA: { [key: string]: RuleCriteria } = {
    equals: { criteria: 'EQUAL', label: 'Is equal to' },
    notEquals: { criteria: 'NOT_EQUAL', label: 'Is not equal to' },
    greaterThan: { criteria: 'GREATER_THAN', label: 'Is greater than' },
    greaterThanEqualTo: { criteria: 'GREATER_THAN_OR_EQUAL', label: 'Is greater than or equal to' },
    lessThan: { criteria: 'LESS_THAN', label: 'Is less than' },
    lessThanEqualTo: { criteria: 'LESS_THAN_OR_EQUAL', label: 'Is less than or equal to' },
    startsWith: { criteria: 'STARTS_WITH', label: 'Starts with' },
    endsWith: { criteria: 'ENDS_WITH', label: 'Ends with' },
    contains: { criteria: 'CONTAINS', label: 'Contains' },
    isEmpty: { criteria: 'IS_EMPTY', label: 'Is empty' },
    allEqual: { criteria: 'ALL_EQUAL', label: 'All are equal to' },
    anyEqual: { criteria: 'ANY_EQUAL', label: 'Any are equal to' },
};

export const RULE_CRITERIA_OPTIONS: { [key: string]: RuleCriteria[] } = {
    contentString: [
        RULE_CRITERIA['equals'],
        RULE_CRITERIA['notEquals'],
        RULE_CRITERIA['greaterThan'],
        RULE_CRITERIA['greaterThanEqualTo'],
        RULE_CRITERIA['lessThan'],
        RULE_CRITERIA['lessThanEqualTo'],
        RULE_CRITERIA['startsWith'],
        RULE_CRITERIA['endsWith'],
        RULE_CRITERIA['contains'],
        RULE_CRITERIA['isEmpty'],
    ],
    contentBoolean: [RULE_CRITERIA['equals'], RULE_CRITERIA['notEquals'], RULE_CRITERIA['isEmpty']],
    contentContent: [
        RULE_CRITERIA['equals'],
        RULE_CRITERIA['notEquals'],
        RULE_CRITERIA['greaterThan'],
        RULE_CRITERIA['greaterThanEqualTo'],
        RULE_CRITERIA['lessThan'],
        RULE_CRITERIA['lessThanEqualTo'],
        RULE_CRITERIA['startsWith'],
        RULE_CRITERIA['endsWith'],
        RULE_CRITERIA['contains'],
        RULE_CRITERIA['isEmpty'],
    ],
    contentPassword: [
        RULE_CRITERIA['equals'],
        RULE_CRITERIA['notEquals'],
        RULE_CRITERIA['greaterThan'],
        RULE_CRITERIA['greaterThanEqualTo'],
        RULE_CRITERIA['lessThan'],
        RULE_CRITERIA['lessThanEqualTo'],
        RULE_CRITERIA['startsWith'],
        RULE_CRITERIA['endsWith'],
        RULE_CRITERIA['contains'],
        RULE_CRITERIA['isEmpty'],
    ],
    contentEncrypted: [
        RULE_CRITERIA['equals'],
        RULE_CRITERIA['notEquals'],
        RULE_CRITERIA['greaterThan'],
        RULE_CRITERIA['greaterThanEqualTo'],
        RULE_CRITERIA['lessThan'],
        RULE_CRITERIA['lessThanEqualTo'],
        RULE_CRITERIA['startsWith'],
        RULE_CRITERIA['endsWith'],
        RULE_CRITERIA['contains'],
        RULE_CRITERIA['isEmpty'],
    ],
    contentDateTime: [
        RULE_CRITERIA['equals'],
        RULE_CRITERIA['notEquals'],
        RULE_CRITERIA['greaterThan'],
        RULE_CRITERIA['greaterThanEqualTo'],
        RULE_CRITERIA['lessThan'],
        RULE_CRITERIA['lessThanEqualTo'],
        RULE_CRITERIA['isEmpty'],
    ],
    contentDate: [
        RULE_CRITERIA['equals'],
        RULE_CRITERIA['notEquals'],
        RULE_CRITERIA['greaterThan'],
        RULE_CRITERIA['greaterThanEqualTo'],
        RULE_CRITERIA['lessThan'],
        RULE_CRITERIA['lessThanEqualTo'],
        RULE_CRITERIA['isEmpty'],
    ],
    contentNumber: [
        RULE_CRITERIA['equals'],
        RULE_CRITERIA['notEquals'],
        RULE_CRITERIA['greaterThan'],
        RULE_CRITERIA['greaterThanEqualTo'],
        RULE_CRITERIA['lessThan'],
        RULE_CRITERIA['lessThanEqualTo'],
        RULE_CRITERIA['isEmpty'],
    ],
    contentList: [RULE_CRITERIA['isEmpty']],
    contentListProperty: [RULE_CRITERIA['allEqual'], RULE_CRITERIA['anyEqual']],
    contentObject: [RULE_CRITERIA['isEmpty']],
};
