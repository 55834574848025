import translations from '../translations';
import type { ContentType } from '../types';

export const CONTENT_TYPES_LIST = [
    { key: 'CONTENTSTRING', label: 'String' },
    { key: 'CONTENTNUMBER', label: 'Number' },
    { key: 'CONTENTBOOLEAN', label: 'Boolean' },
    { key: 'CONTENTPASSWORD', label: 'Password' },
    { key: 'CONTENTENCRYPTED', label: 'Encrypted' },
    { key: 'CONTENTDATETIME', label: 'DateTime' },
    { key: 'CONTENTDATE', label: 'Date' },
    { key: 'CONTENTCONTENT', label: 'Content' },
    { key: 'CONTENTOBJECT', label: 'Object' },
    { key: 'CONTENTLIST', label: 'List' },
] as const;

type ContentTypes = {
    [ContentType in (typeof CONTENT_TYPES_LIST)[number] as Lowercase<
        ContentType['label']
    >]: ContentType['key'];
};

export const CONTENT_TYPES = CONTENT_TYPES_LIST.reduce(
    (accumulator, currentType) => {
        accumulator[currentType.label.toLowerCase()] = currentType.key;
        return accumulator;
    },
    {} as Record<string, string>,
) as ContentTypes;

export const ACCESS_LEVELS = {
    PRIVATE: {
        value: 'PRIVATE',
        label: `Private - ${translations.AV_private_access_value}`,
        labelShort: 'Private',
        description: translations.AV_private_access_value,
    },
    INPUT: {
        value: 'INPUT',
        label: `Input - ${translations.AV_input_access_value}`,
        labelShort: 'Input',
        description: translations.AV_input_access_value,
    },
    OUTPUT: {
        value: 'OUTPUT',
        label: `Output - ${translations.AV_output_access_value}`,
        labelShort: 'Output',
        description: translations.AV_output_access_value,
    },
    INPUT_OUTPUT: {
        value: 'INPUT_OUTPUT',
        label: `Input & Output - ${translations.AV_input_output_access_value}`,
        labelShort: 'Input & Output',
        description: translations.AV_input_output_access_value,
    },
    PUBLIC: {
        value: 'PUBLIC',
        label: `Public - ${translations.AV_public_access_value}`,
        labelShort: 'Public',
        description: translations.AV_public_access_value,
    },
};

export const VALUE_TYPES: {
    value: ContentType;
    label: string;
    inputType: string;
    requiresType: boolean;
    canBeFormatted: boolean;
}[] = [
    {
        value: 'ContentString',
        inputType: 'text',
        label: 'String',
        requiresType: false,
        canBeFormatted: false,
    },
    {
        value: 'ContentNumber',
        inputType: 'number',
        label: 'Number',
        requiresType: false,
        canBeFormatted: true,
    },
    {
        value: 'ContentPassword',
        inputType: 'password',
        label: 'Password',
        requiresType: false,
        canBeFormatted: false,
    },
    {
        value: 'ContentBoolean',
        inputType: 'checkbox',
        label: 'Boolean',
        requiresType: false,
        canBeFormatted: false,
    },
    {
        value: 'ContentEncrypted',
        inputType: 'text',
        label: 'Encrypted',
        requiresType: false,
        canBeFormatted: false,
    },
    {
        value: 'ContentDateTime',
        inputType: 'text',
        label: 'Date/Time',
        requiresType: false,
        canBeFormatted: true,
    },
    {
        value: 'ContentDate',
        inputType: 'text',
        label: 'Date',
        requiresType: false,
        canBeFormatted: true,
    },
    {
        value: 'ContentContent',
        inputType: 'text',
        label: 'Content',
        requiresType: false,
        canBeFormatted: false,
    },
    {
        value: 'ContentObject',
        inputType: 'text',
        label: 'Object',
        requiresType: true,
        canBeFormatted: false,
    },
    {
        value: 'ContentList',
        inputType: 'text',
        label: 'List',
        requiresType: true,
        canBeFormatted: false,
    },
];
