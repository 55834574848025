import AceEditor, { type IAceEditorProps } from 'react-ace';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-searchbox';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-twilight';

// Re-export prop types to simplify imports, e.g., for creating a mock
export type CodeEditorProps = {
    // Remap props to allow undefined. Causes issues otherwise when using exactOptionalPropertyTypes in tsconfig
    [Properties in keyof IAceEditorProps]: IAceEditorProps[Properties] | undefined | null;
};

const CodeEditor = (props: CodeEditorProps) => (
    // Fails in browser without .default; fails in tests with it. AceEditor doesn't work in JSDOM anyway, so mock it
    // @ts-expect-error Apparently AceEditor at this point is a module, not a component
    <AceEditor.default
        mode="json"
        theme="twilight"
        name="code-editor"
        height="100%"
        width="100%"
        fontSize={14}
        setOptions={{ useWorker: false }}
        {...props}
    />
);

export default CodeEditor;
